h1 {
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.16rem;
  letter-spacing: -0.018rem;
}

h2 {
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.65rem;
  letter-spacing: -0.01375rem;
}

h3 {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.35rem;   
  letter-spacing: -0.01125rem;
}
