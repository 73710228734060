@tailwind base;
@tailwind components;
@tailwind utilities;

.body-normal {
  font-size: 1rem;
  font-style: normal;
  line-height: 1.3rem;
  letter-spacing: -0.01rem;
}

.body-small {
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.1375rem;
  letter-spacing: -0.00875rem;
}
